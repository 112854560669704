/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPermission,
    UserPermissionFromJSON,
    UserPermissionFromJSONTyped,
    UserPermissionToJSON,
} from './UserPermission';
import {
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface UserBasicInfo
 */
export interface UserBasicInfo {
    /**
     * 
     * @type {number}
     * @memberof UserBasicInfo
     */
    ensUserTid: number;
    /**
     * 
     * @type {string}
     * @memberof UserBasicInfo
     */
    userEmail: string;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof UserBasicInfo
     */
    roles: Array<UserRole>;
    /**
     * 
     * @type {Array<UserPermission>}
     * @memberof UserBasicInfo
     */
    permissions: Array<UserPermission>;
}

export function UserBasicInfoFromJSON(json: any): UserBasicInfo {
    return UserBasicInfoFromJSONTyped(json, false);
}

export function UserBasicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBasicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ensUserTid': json['ensUserTid'],
        'userEmail': json['userEmail'],
        'roles': ((json['roles'] as Array<any>).map(UserRoleFromJSON)),
        'permissions': ((json['permissions'] as Array<any>).map(UserPermissionFromJSON)),
    };
}

export function UserBasicInfoToJSON(value?: UserBasicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ensUserTid': value.ensUserTid,
        'userEmail': value.userEmail,
        'roles': ((value.roles as Array<any>).map(UserRoleToJSON)),
        'permissions': ((value.permissions as Array<any>).map(UserPermissionToJSON)),
    };
}

