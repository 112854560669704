
            export enum EnsUserNoteTypeTid {
                    ExcusedAbsenceSick = 10,
    PolicyViolationLate = 11,
    PolicyViolationEarlyOut = 12,
    PolicyViolationBreak = 13,
    PolicyViolationMeal = 14,
    UnexcusedAbsenceSick = 15,
    UnexcusedAbsenceNoCallShow = 16,
    UnexcusedAbsenceOther = 17,
    ExcusedAbsencePTO = 18,
    ExcusedAbsenseFMLA = 19,
    VerbalWarning = 20,
    WrittenWarning = 21,
    FinalWrittenWarning = 22,
    CallAvoidance = 23,
    TimecardAbuse = 24,
    UnexcusedAbsenceNoCallNoShow = 25,
    ExcusedAbsenceLwop = 26,
    OtherCorrectiveConversationDocumentation = 27,
    OtherOneOnOneDocumentation = 28,
    ExcusedAbsenceBereavement = 29,
    OtherHRDocumentation = 30,
    AbsenceRequest = 31,
    PolicyViolationSensitiveData = 32,

            }

            export function EnsUserNoteTypeTidFromJSON(json: any): EnsUserNoteTypeTid {
                return EnsUserNoteTypeTidFromJSONTyped(json, false);
            }

            export function EnsUserNoteTypeTidFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnsUserNoteTypeTid {
                return json as EnsUserNoteTypeTid;
            }

            export function EnsUserNoteTypeTidToJSON(value?: EnsUserNoteTypeTid | null): any {
                return value as any;
            }