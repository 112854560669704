
            export enum EnsUserRoleType {
                    ContactCenterAgent = 1,
    ContactCenterSupervisor = 2,
    LicensingAssociate = 3,
    DirectorofDigitalMarketing = 4,
    HrGeneralist = 5,
    ComplianceAnalyst = 7,
    IapOwner = 8,
    BackendDeveloper = 9,
    Programmer = 10,
    HeadofTechnology = 11,
    ChiefExecutiveOfficer = 12,
    Custodian = 13,
    ProjectManager = 14,
    DirectorofStrategicInitiatives = 15,
    SoftwareQaEngineer = 16,
    ContactCenterTrainer = 17,
    Accountant = 19,
    Controller = 20,
    ComplianceOfficer = 21,
    ManagerofAgentLicensing = 22,
    NewBusinessCoordinator = 23,
    DialerAnalyst = 24,
    DirectoroftheContactCenter = 26,
    CreativeDirector = 27,
    FrontEndDeveloper = 28,
    VisualDesigner = 29,
    MarketingAnalyticsManager = 34,
    ItHelpDeskSupport = 35,
    VicePresident = 36,
    ManagerofHumanResources = 38,
    DigitalMarketingSpecialist = 39,
    MarketingContentSpecialist = 40,
    Systems = 42,
    HeadofBusinessDevelopmment = 43,
    TechnologySupervisor = 44,
    QaAnalyst = 45,
    SaleAdmin = 46,
    SalesforceAdministrator = 47,
    RevenueAnalyst = 48,
    SalesAnalyst = 49,
    DatabaseAdministrator = 50,
    Fronter = 51,
    LicensingContractingSpecialist = 52,
    ChiefMarketingOfficer = 53,
    DialerAnalystI = 54,
    LeadGenerationSupervisor = 55,
    CloudInfrastructureManager = 56,
    MarketingCoordinator = 57,
    RetentionSpecialist = 58,
    DataArchitect = 59,
    MarketingOperationsAdmin = 60,
    ApArSpecialist = 61,
    VpSales = 62,
    VpMarketing = 63,
    FinanceOperationsAnalyst = 64,
    DirectorofCustomerStrategy = 65,
    Security = 66,

            }

            export function EnsUserRoleTypeFromJSON(json: any): EnsUserRoleType {
                return EnsUserRoleTypeFromJSONTyped(json, false);
            }

            export function EnsUserRoleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnsUserRoleType {
                return json as EnsUserRoleType;
            }

            export function EnsUserRoleTypeToJSON(value?: EnsUserRoleType | null): any {
                return value as any;
            }