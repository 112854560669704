import React, { useEffect } from "react";
import Tab from "./Tab";
import "./App.css";
import { app } from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "../internal/context";

export default function App() {
  const { loading, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
    clientId: process.env.REACT_APP_CLIENT_ID!
  });

  useEffect(() => {
    if (loading)
      app.initialize().then(async () => app.notifySuccess())
  }, [loading])

  return (
    <TeamsFxContext.Provider value={{ themeString, teamsUserCredential }}>
      {/* <FluentProvider
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
              ? teamsHighContrastTheme
              : teamsLightTheme
        }
        style={{ background: tokens.colorNeutralBackground3 }}
      > */}
      {!loading && <Tab />}
      {/* </FluentProvider> */}
    </TeamsFxContext.Provider>
  );
}
