/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmpCreateNoteRequestAttachment,
    EmpCreateNoteRequestAttachmentFromJSON,
    EmpCreateNoteRequestAttachmentFromJSONTyped,
    EmpCreateNoteRequestAttachmentToJSON,
} from './EmpCreateNoteRequestAttachment';
import {
    EmpGetNoteTagsResponse,
    EmpGetNoteTagsResponseFromJSON,
    EmpGetNoteTagsResponseFromJSONTyped,
    EmpGetNoteTagsResponseToJSON,
} from './EmpGetNoteTagsResponse';

/**
 * 
 * @export
 * @interface EmpCreateNoteRequest
 */
export interface EmpCreateNoteRequest {
    /**
     * 
     * @type {number}
     * @memberof EmpCreateNoteRequest
     */
    authorEnsUserTid: number;
    /**
     * 
     * @type {number}
     * @memberof EmpCreateNoteRequest
     */
    subjectEnsUserTid: number;
    /**
     * 
     * @type {number}
     * @memberof EmpCreateNoteRequest
     */
    ensUserNoteTypeTid: number;
    /**
     * 
     * @type {Date}
     * @memberof EmpCreateNoteRequest
     */
    effectiveDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof EmpCreateNoteRequest
     */
    noteContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpCreateNoteRequest
     */
    hiddenNoteContent?: string | null;
    /**
     * 
     * @type {Array<EmpGetNoteTagsResponse>}
     * @memberof EmpCreateNoteRequest
     */
    noteTags?: Array<EmpGetNoteTagsResponse> | null;
    /**
     * 
     * @type {Array<EmpCreateNoteRequestAttachment>}
     * @memberof EmpCreateNoteRequest
     */
    noteAttachments?: Array<EmpCreateNoteRequestAttachment> | null;
}

export function EmpCreateNoteRequestFromJSON(json: any): EmpCreateNoteRequest {
    return EmpCreateNoteRequestFromJSONTyped(json, false);
}

export function EmpCreateNoteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmpCreateNoteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorEnsUserTid': json['author_ens_user_tid'],
        'subjectEnsUserTid': json['subject_ens_user_tid'],
        'ensUserNoteTypeTid': json['ens_user_note_type_tid'],
        'effectiveDate': !exists(json, 'effective_date') ? undefined : (json['effective_date'] === null ? null : new Date(json['effective_date'])),
        'noteContent': !exists(json, 'note_content') ? undefined : json['note_content'],
        'hiddenNoteContent': !exists(json, 'hidden_note_content') ? undefined : json['hidden_note_content'],
        'noteTags': !exists(json, 'note_tags') ? undefined : (json['note_tags'] === null ? null : (json['note_tags'] as Array<any>).map(EmpGetNoteTagsResponseFromJSON)),
        'noteAttachments': !exists(json, 'note_attachments') ? undefined : (json['note_attachments'] === null ? null : (json['note_attachments'] as Array<any>).map(EmpCreateNoteRequestAttachmentFromJSON)),
    };
}

export function EmpCreateNoteRequestToJSON(value?: EmpCreateNoteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author_ens_user_tid': value.authorEnsUserTid,
        'subject_ens_user_tid': value.subjectEnsUserTid,
        'ens_user_note_type_tid': value.ensUserNoteTypeTid,
        'effective_date': value.effectiveDate === undefined ? undefined : (value.effectiveDate === null ? null : value.effectiveDate.toISOString()),
        'note_content': value.noteContent,
        'hidden_note_content': value.hiddenNoteContent,
        'note_tags': value.noteTags === undefined ? undefined : (value.noteTags === null ? null : (value.noteTags as Array<any>).map(EmpGetNoteTagsResponseToJSON)),
        'note_attachments': value.noteAttachments === undefined ? undefined : (value.noteAttachments === null ? null : (value.noteAttachments as Array<any>).map(EmpCreateNoteRequestAttachmentToJSON)),
    };
}

