/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmpGetNoteTagsResponse
 */
export interface EmpGetNoteTagsResponse {
    /**
     * 
     * @type {number}
     * @memberof EmpGetNoteTagsResponse
     */
    ensUserNoteTagTid: number;
    /**
     * 
     * @type {string}
     * @memberof EmpGetNoteTagsResponse
     */
    tagName: string;
    /**
     * 
     * @type {string}
     * @memberof EmpGetNoteTagsResponse
     */
    tagColor: string;
    /**
     * 
     * @type {number}
     * @memberof EmpGetNoteTagsResponse
     */
    noteTypeTid?: number | null;
}

export function EmpGetNoteTagsResponseFromJSON(json: any): EmpGetNoteTagsResponse {
    return EmpGetNoteTagsResponseFromJSONTyped(json, false);
}

export function EmpGetNoteTagsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmpGetNoteTagsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ensUserNoteTagTid': json['ensUserNoteTagTid'],
        'tagName': json['tagName'],
        'tagColor': json['tagColor'],
        'noteTypeTid': !exists(json, 'noteTypeTid') ? undefined : json['noteTypeTid'],
    };
}

export function EmpGetNoteTagsResponseToJSON(value?: EmpGetNoteTagsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ensUserNoteTagTid': value.ensUserNoteTagTid,
        'tagName': value.tagName,
        'tagColor': value.tagColor,
        'noteTypeTid': value.noteTypeTid,
    };
}

