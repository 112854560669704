/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmpGetAllUsersManagedByAuthorResponse,
    EmpGetAllUsersManagedByAuthorResponseFromJSON,
    EmpGetAllUsersManagedByAuthorResponseToJSON,
    EmpGetNoteCategoriesResponse,
    EmpGetNoteCategoriesResponseFromJSON,
    EmpGetNoteCategoriesResponseToJSON,
    EmpGetNoteTagsResponse,
    EmpGetNoteTagsResponseFromJSON,
    EmpGetNoteTagsResponseToJSON,
    EmpUserInfo,
    EmpUserInfoFromJSON,
    EmpUserInfoToJSON,
} from '../models';

export interface EmpLookupGetAllUsersManagedByAuthorRequest {
    authorEnsUserTid: number;
}

export interface EmpLookupGetNoteCategoriesForAuthorRequest {
    authorEnsUserTid: number;
    showHidden?: boolean;
}

export interface EmpLookupGetNoteTagsForNoteTypeRequest {
    ensNoteTypeTid: number;
}

export interface EmpLookupGetUserManagerRequest {
    ensUserTid: number;
}

/**
 * 
 */
export class EmpLookupApi extends runtime.BaseAPI {

    /**
     */
    async empLookupGetAllUsersManagedByAuthorRaw(requestParameters: EmpLookupGetAllUsersManagedByAuthorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmpGetAllUsersManagedByAuthorResponse>> {
        if (requestParameters.authorEnsUserTid === null || requestParameters.authorEnsUserTid === undefined) {
            throw new runtime.RequiredError('authorEnsUserTid','Required parameter requestParameters.authorEnsUserTid was null or undefined when calling empLookupGetAllUsersManagedByAuthor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT Token authentication
        }

        const response = await this.request({
            path: `/EmpLookup/managed-by/{authorEnsUserTid}`.replace(`{${"authorEnsUserTid"}}`, encodeURIComponent(String(requestParameters.authorEnsUserTid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmpGetAllUsersManagedByAuthorResponseFromJSON(jsonValue));
    }

    /**
     */
    async empLookupGetAllUsersManagedByAuthor(requestParameters: EmpLookupGetAllUsersManagedByAuthorRequest, initOverrides?: RequestInit): Promise<EmpGetAllUsersManagedByAuthorResponse> {
        const response = await this.empLookupGetAllUsersManagedByAuthorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async empLookupGetNoteCategoriesForAuthorRaw(requestParameters: EmpLookupGetNoteCategoriesForAuthorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<EmpGetNoteCategoriesResponse>>> {
        if (requestParameters.authorEnsUserTid === null || requestParameters.authorEnsUserTid === undefined) {
            throw new runtime.RequiredError('authorEnsUserTid','Required parameter requestParameters.authorEnsUserTid was null or undefined when calling empLookupGetNoteCategoriesForAuthor.');
        }

        const queryParameters: any = {};

        if (requestParameters.showHidden !== undefined) {
            queryParameters['showHidden'] = requestParameters.showHidden;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT Token authentication
        }

        const response = await this.request({
            path: `/EmpLookup/notes/categories/{authorEnsUserTid}`.replace(`{${"authorEnsUserTid"}}`, encodeURIComponent(String(requestParameters.authorEnsUserTid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmpGetNoteCategoriesResponseFromJSON));
    }

    /**
     */
    async empLookupGetNoteCategoriesForAuthor(requestParameters: EmpLookupGetNoteCategoriesForAuthorRequest, initOverrides?: RequestInit): Promise<Array<EmpGetNoteCategoriesResponse>> {
        const response = await this.empLookupGetNoteCategoriesForAuthorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async empLookupGetNoteTagsForNoteTypeRaw(requestParameters: EmpLookupGetNoteTagsForNoteTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<EmpGetNoteTagsResponse>>> {
        if (requestParameters.ensNoteTypeTid === null || requestParameters.ensNoteTypeTid === undefined) {
            throw new runtime.RequiredError('ensNoteTypeTid','Required parameter requestParameters.ensNoteTypeTid was null or undefined when calling empLookupGetNoteTagsForNoteType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT Token authentication
        }

        const response = await this.request({
            path: `/notes/tags/{ensNoteTypeTid}`.replace(`{${"ensNoteTypeTid"}}`, encodeURIComponent(String(requestParameters.ensNoteTypeTid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmpGetNoteTagsResponseFromJSON));
    }

    /**
     */
    async empLookupGetNoteTagsForNoteType(requestParameters: EmpLookupGetNoteTagsForNoteTypeRequest, initOverrides?: RequestInit): Promise<Array<EmpGetNoteTagsResponse>> {
        const response = await this.empLookupGetNoteTagsForNoteTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async empLookupGetUserManagerRaw(requestParameters: EmpLookupGetUserManagerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmpUserInfo>> {
        if (requestParameters.ensUserTid === null || requestParameters.ensUserTid === undefined) {
            throw new runtime.RequiredError('ensUserTid','Required parameter requestParameters.ensUserTid was null or undefined when calling empLookupGetUserManager.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT Token authentication
        }

        const response = await this.request({
            path: `/EmpLookup/{ensUserTid}/manager`.replace(`{${"ensUserTid"}}`, encodeURIComponent(String(requestParameters.ensUserTid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmpUserInfoFromJSON(jsonValue));
    }

    /**
     */
    async empLookupGetUserManager(requestParameters: EmpLookupGetUserManagerRequest, initOverrides?: RequestInit): Promise<EmpUserInfo> {
        const response = await this.empLookupGetUserManagerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
