/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserBasicInfo,
    UserBasicInfoFromJSON,
    UserBasicInfoToJSON,
} from '../models';

export interface UserInfoGetBasicInfoRequest {
    ensUserTid?: number | null;
    userEmail?: string | null;
}

/**
 * 
 */
export class UserInfoApi extends runtime.BaseAPI {

    /**
     */
    async userInfoGetBasicInfoRaw(requestParameters: UserInfoGetBasicInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserBasicInfo>> {
        const queryParameters: any = {};

        if (requestParameters.ensUserTid !== undefined) {
            queryParameters['ensUserTid'] = requestParameters.ensUserTid;
        }

        if (requestParameters.userEmail !== undefined) {
            queryParameters['userEmail'] = requestParameters.userEmail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT Token authentication
        }

        const response = await this.request({
            path: `/UserInfo/basic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBasicInfoFromJSON(jsonValue));
    }

    /**
     */
    async userInfoGetBasicInfo(requestParameters: UserInfoGetBasicInfoRequest, initOverrides?: RequestInit): Promise<UserBasicInfo> {
        const response = await this.userInfoGetBasicInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
