/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnsUserPermissionType,
    EnsUserPermissionTypeFromJSON,
    EnsUserPermissionTypeFromJSONTyped,
    EnsUserPermissionTypeToJSON,
} from './EnsUserPermissionType';

/**
 * 
 * @export
 * @interface UserPermission
 */
export interface UserPermission {
    /**
     * 
     * @type {EnsUserPermissionType}
     * @memberof UserPermission
     */
    ensUserPermissionType: EnsUserPermissionType;
    /**
     * 
     * @type {string}
     * @memberof UserPermission
     */
    ensUserPermissionName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPermission
     */
    isRolePermission: boolean;
}

export function UserPermissionFromJSON(json: any): UserPermission {
    return UserPermissionFromJSONTyped(json, false);
}

export function UserPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPermission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ensUserPermissionType': EnsUserPermissionTypeFromJSON(json['ensUserPermissionType']),
        'ensUserPermissionName': json['ensUserPermissionName'],
        'isRolePermission': json['isRolePermission'],
    };
}

export function UserPermissionToJSON(value?: UserPermission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ensUserPermissionType': EnsUserPermissionTypeToJSON(value.ensUserPermissionType),
        'ensUserPermissionName': value.ensUserPermissionName,
        'isRolePermission': value.isRolePermission,
    };
}

