import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import "./index.css";

const container = document.getElementById("root");

if (!container)
    throw new Error("Failed to create DOM root.");

const root = createRoot(container);
root.render(<App />);
