
            export enum EnsUserPermissionType {
                    AdminAccess = 1,
    AdminEmployeePermissions = 2,
    ComplianceAccess = 3,
    LicensingAccessAll = 4,
    LicensingAccessSelf = 5,
    LicensingEditAll = 6,
    MarketingAccess = 7,
    OperationsAccess = 8,
    SalesPortalAccess = 9,
    DtcAccess = 10,
    AgentSaleAccess = 11,
    AdminSaleAccess = 12,
    ControllerSaleAaccess = 13,
    LicensingExperimentalAccess = 14,
    CarrierChildrenMaintenanceAccess = 15,
    SocialSecurityAccess = 16,
    CallAudioAccess = 17,
    DataDictionaryAccess = 18,
    DataDictionaryEdit = 19,
    SupervisorSaleAccess = 20,
    CallAnalyticsAccess = 21,
    AlertsAccess = 22,
    CronBossAccess = 23,
    TimesheetUploadAccess = 24,
    BusinessTermMaintainerAccess = 25,
    LicensingRequestApprovalAccess = 26,
    CommissionUploadManagerViewOnlyAccess = 27,
    SalePledgeAccess = 28,
    EcmViewApp = 29,
    EcmGetStreamers = 30,
    EcmWatchStream = 31,
    EcmGetVideoUrl = 32,
    EcmListVideos = 33,
    EcmVideoInsights = 34,
    ReadSalesCalls = 35,
    ReadCallLogs = 36,
    ReadTranscripts = 37,
    ReadCallAnalyticsResults = 38,
    SaleMassUpdateAccess = 39,
    SalesPolicyHistoryEditAccess = 40,
    EmpDownloadAllUserAttachments = 41,
    EMPEmployeeAccess = 42,
    EMPAuditAccess = 43,
    EMPSupervisorAccess = 44,
    EMPNegativeCategoryAcess = 45,
    EmployeeRelationshipUploadAccess = 46,
    HRAccess = 47,
    EcmGetAdminMetrics = 48,
    EcmGetDeveloperMetrics = 49,
    OrganizationChartAccess = 50,
    EmpDeleteAccess = 51,
    BulkListImportAccess = 52,
    EmpLwopCategoryAccess = 53,
    EmpOnOnOneCategoryAccess = 54,
    EmpSensitiveDataCategoryAccess = 55,

            }

            export function EnsUserPermissionTypeFromJSON(json: any): EnsUserPermissionType {
                return EnsUserPermissionTypeFromJSONTyped(json, false);
            }

            export function EnsUserPermissionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnsUserPermissionType {
                return json as EnsUserPermissionType;
            }

            export function EnsUserPermissionTypeToJSON(value?: EnsUserPermissionType | null): any {
                return value as any;
            }