/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmpCreateNoteRequestAttachment
 */
export interface EmpCreateNoteRequestAttachment {
    /**
     * 
     * @type {string}
     * @memberof EmpCreateNoteRequestAttachment
     */
    pathPrefix: string;
    /**
     * 
     * @type {string}
     * @memberof EmpCreateNoteRequestAttachment
     */
    uploadGuid: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmpCreateNoteRequestAttachment
     */
    isPrivate: boolean;
}

export function EmpCreateNoteRequestAttachmentFromJSON(json: any): EmpCreateNoteRequestAttachment {
    return EmpCreateNoteRequestAttachmentFromJSONTyped(json, false);
}

export function EmpCreateNoteRequestAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmpCreateNoteRequestAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pathPrefix': json['path_prefix'],
        'uploadGuid': json['upload_guid'],
        'isPrivate': json['is_private'],
    };
}

export function EmpCreateNoteRequestAttachmentToJSON(value?: EmpCreateNoteRequestAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path_prefix': value.pathPrefix,
        'upload_guid': value.uploadGuid,
        'is_private': value.isPrivate,
    };
}

