/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelationStateInfo
 */
export interface RelationStateInfo {
    /**
     * 
     * @type {number}
     * @memberof RelationStateInfo
     */
    deletesNote?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationStateInfo
     */
    deletedByNote?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof RelationStateInfo
     */
    deletedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof RelationStateInfo
     */
    revertsNote?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationStateInfo
     */
    revertedByNote?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof RelationStateInfo
     */
    revertedDate?: Date | null;
}

export function RelationStateInfoFromJSON(json: any): RelationStateInfo {
    return RelationStateInfoFromJSONTyped(json, false);
}

export function RelationStateInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelationStateInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deletesNote': !exists(json, 'deletesNote') ? undefined : json['deletesNote'],
        'deletedByNote': !exists(json, 'deletedByNote') ? undefined : json['deletedByNote'],
        'deletedDate': !exists(json, 'deletedDate') ? undefined : (json['deletedDate'] === null ? null : new Date(json['deletedDate'])),
        'revertsNote': !exists(json, 'revertsNote') ? undefined : json['revertsNote'],
        'revertedByNote': !exists(json, 'revertedByNote') ? undefined : json['revertedByNote'],
        'revertedDate': !exists(json, 'revertedDate') ? undefined : (json['revertedDate'] === null ? null : new Date(json['revertedDate'])),
    };
}

export function RelationStateInfoToJSON(value?: RelationStateInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deletesNote': value.deletesNote,
        'deletedByNote': value.deletedByNote,
        'deletedDate': value.deletedDate === undefined ? undefined : (value.deletedDate === null ? null : value.deletedDate.toISOString()),
        'revertsNote': value.revertsNote,
        'revertedByNote': value.revertedByNote,
        'revertedDate': value.revertedDate === undefined ? undefined : (value.revertedDate === null ? null : value.revertedDate.toISOString()),
    };
}

