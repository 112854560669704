/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmpUserNoteAttachment
 */
export interface EmpUserNoteAttachment {
    /**
     * 
     * @type {number}
     * @memberof EmpUserNoteAttachment
     */
    ensUserNoteAttachmentTid: number;
    /**
     * 
     * @type {Date}
     * @memberof EmpUserNoteAttachment
     */
    createdDatetime: Date;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNoteAttachment
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNoteAttachment
     */
    pathPrefix: string;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNoteAttachment
     */
    uploadGuid: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmpUserNoteAttachment
     */
    isPrivate: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EmpUserNoteAttachment
     */
    deletedDatetime?: Date | null;
}

export function EmpUserNoteAttachmentFromJSON(json: any): EmpUserNoteAttachment {
    return EmpUserNoteAttachmentFromJSONTyped(json, false);
}

export function EmpUserNoteAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmpUserNoteAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ensUserNoteAttachmentTid': json['ensUserNoteAttachmentTid'],
        'createdDatetime': (new Date(json['createdDatetime'])),
        'createdBy': json['createdBy'],
        'pathPrefix': json['pathPrefix'],
        'uploadGuid': json['uploadGuid'],
        'isPrivate': json['isPrivate'],
        'deletedDatetime': !exists(json, 'deletedDatetime') ? undefined : (json['deletedDatetime'] === null ? null : new Date(json['deletedDatetime'])),
    };
}

export function EmpUserNoteAttachmentToJSON(value?: EmpUserNoteAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ensUserNoteAttachmentTid': value.ensUserNoteAttachmentTid,
        'createdDatetime': (value.createdDatetime.toISOString()),
        'createdBy': value.createdBy,
        'pathPrefix': value.pathPrefix,
        'uploadGuid': value.uploadGuid,
        'isPrivate': value.isPrivate,
        'deletedDatetime': value.deletedDatetime === undefined ? undefined : (value.deletedDatetime === null ? null : value.deletedDatetime.toISOString()),
    };
}

