/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnsUserRoleType,
    EnsUserRoleTypeFromJSON,
    EnsUserRoleTypeFromJSONTyped,
    EnsUserRoleTypeToJSON,
} from './EnsUserRoleType';

/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {EnsUserRoleType}
     * @memberof UserRole
     */
    ensUserRoleType: EnsUserRoleType;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    ensUserRoleName: string;
}

export function UserRoleFromJSON(json: any): UserRole {
    return UserRoleFromJSONTyped(json, false);
}

export function UserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ensUserRoleType': EnsUserRoleTypeFromJSON(json['ensUserRoleType']),
        'ensUserRoleName': json['ensUserRoleName'],
    };
}

export function UserRoleToJSON(value?: UserRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ensUserRoleType': EnsUserRoleTypeToJSON(value.ensUserRoleType),
        'ensUserRoleName': value.ensUserRoleName,
    };
}

