/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmpCreateNoteRequest,
    EmpCreateNoteRequestFromJSON,
    EmpCreateNoteRequestToJSON,
    EmpUserNote,
    EmpUserNoteFromJSON,
    EmpUserNoteToJSON,
} from '../models';

export interface EmpNotesGetNoteByTidRequest {
    currentUserTid: number;
    noteTid: number;
}

export interface EmpNotesGetUserNotesRequest {
    authorEnsUserTid?: number;
    subjectEnsUserTid?: number;
}

export interface EmpNotesInsertNewEmpNoteRequest {
    empCreateNoteRequest: EmpCreateNoteRequest;
}

/**
 * 
 */
export class EmpNotesApi extends runtime.BaseAPI {

    /**
     */
    async empNotesGetNoteByTidRaw(requestParameters: EmpNotesGetNoteByTidRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmpUserNote>> {
        if (requestParameters.currentUserTid === null || requestParameters.currentUserTid === undefined) {
            throw new runtime.RequiredError('currentUserTid','Required parameter requestParameters.currentUserTid was null or undefined when calling empNotesGetNoteByTid.');
        }

        if (requestParameters.noteTid === null || requestParameters.noteTid === undefined) {
            throw new runtime.RequiredError('noteTid','Required parameter requestParameters.noteTid was null or undefined when calling empNotesGetNoteByTid.');
        }

        const queryParameters: any = {};

        if (requestParameters.currentUserTid !== undefined) {
            queryParameters['currentUserTid'] = requestParameters.currentUserTid;
        }

        if (requestParameters.noteTid !== undefined) {
            queryParameters['noteTid'] = requestParameters.noteTid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT Token authentication
        }

        const response = await this.request({
            path: `/EmpNotes/notes/subject/:note_tid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmpUserNoteFromJSON(jsonValue));
    }

    /**
     */
    async empNotesGetNoteByTid(requestParameters: EmpNotesGetNoteByTidRequest, initOverrides?: RequestInit): Promise<EmpUserNote> {
        const response = await this.empNotesGetNoteByTidRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async empNotesGetUserNotesRaw(requestParameters: EmpNotesGetUserNotesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<EmpUserNote>>> {
        const queryParameters: any = {};

        if (requestParameters.authorEnsUserTid !== undefined) {
            queryParameters['authorEnsUserTid'] = requestParameters.authorEnsUserTid;
        }

        if (requestParameters.subjectEnsUserTid !== undefined) {
            queryParameters['subjectEnsUserTid'] = requestParameters.subjectEnsUserTid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT Token authentication
        }

        const response = await this.request({
            path: `/EmpNotes/notes/subject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmpUserNoteFromJSON));
    }

    /**
     */
    async empNotesGetUserNotes(requestParameters: EmpNotesGetUserNotesRequest, initOverrides?: RequestInit): Promise<Array<EmpUserNote>> {
        const response = await this.empNotesGetUserNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async empNotesInsertNewEmpNoteRaw(requestParameters: EmpNotesInsertNewEmpNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.empCreateNoteRequest === null || requestParameters.empCreateNoteRequest === undefined) {
            throw new runtime.RequiredError('empCreateNoteRequest','Required parameter requestParameters.empCreateNoteRequest was null or undefined when calling empNotesInsertNewEmpNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT Token authentication
        }

        const response = await this.request({
            path: `/EmpNotes/notes/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmpCreateNoteRequestToJSON(requestParameters.empCreateNoteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async empNotesInsertNewEmpNote(requestParameters: EmpNotesInsertNewEmpNoteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.empNotesInsertNewEmpNoteRaw(requestParameters, initOverrides);
    }

}
