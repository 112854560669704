/* tslint:disable */
/* eslint-disable */
/**
 * Ensurem API - ensusers Microservice
 * Supports many Ensurem functions through REST APIs.
 *
 * The version of the OpenAPI document: v1
 * Contact: engineering@ensurem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmpGetNoteTagsResponse,
    EmpGetNoteTagsResponseFromJSON,
    EmpGetNoteTagsResponseFromJSONTyped,
    EmpGetNoteTagsResponseToJSON,
} from './EmpGetNoteTagsResponse';
import {
    EmpUserNoteAttachment,
    EmpUserNoteAttachmentFromJSON,
    EmpUserNoteAttachmentFromJSONTyped,
    EmpUserNoteAttachmentToJSON,
} from './EmpUserNoteAttachment';
import {
    EnsUserNoteTypeTid,
    EnsUserNoteTypeTidFromJSON,
    EnsUserNoteTypeTidFromJSONTyped,
    EnsUserNoteTypeTidToJSON,
} from './EnsUserNoteTypeTid';
import {
    RelationStateInfo,
    RelationStateInfoFromJSON,
    RelationStateInfoFromJSONTyped,
    RelationStateInfoToJSON,
} from './RelationStateInfo';

/**
 * 
 * @export
 * @interface EmpUserNote
 */
export interface EmpUserNote {
    /**
     * 
     * @type {number}
     * @memberof EmpUserNote
     */
    ensUserNoteTid: number;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNote
     */
    empNoteAuthorFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNote
     */
    empNoteAuthorLastName: string;
    /**
     * 
     * @type {number}
     * @memberof EmpUserNote
     */
    empNoteAuthorTid: number;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNote
     */
    empNoteSubjectUserFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNote
     */
    empNoteSubjectUserLastName: string;
    /**
     * 
     * @type {number}
     * @memberof EmpUserNote
     */
    empNoteSubjectUserTid: number;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNote
     */
    noteType: string;
    /**
     * 
     * @type {number}
     * @memberof EmpUserNote
     */
    pointValue: number;
    /**
     * 
     * @type {EnsUserNoteTypeTid}
     * @memberof EmpUserNote
     */
    noteTypeTid?: EnsUserNoteTypeTid | null;
    /**
     * 
     * @type {Date}
     * @memberof EmpUserNote
     */
    createdDatetime: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmpUserNote
     */
    effectiveDate: Date;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNote
     */
    empUserNoteContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmpUserNote
     */
    empUserHiddenNoteContent?: string | null;
    /**
     * 
     * @type {Array<EmpUserNoteAttachment>}
     * @memberof EmpUserNote
     */
    noteAttachments: Array<EmpUserNoteAttachment>;
    /**
     * 
     * @type {boolean}
     * @memberof EmpUserNote
     */
    isEditable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmpUserNote
     */
    isDeletable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmpUserNote
     */
    isRevertable: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EmpUserNote
     */
    removedDateTime?: Date | null;
    /**
     * 
     * @type {RelationStateInfo}
     * @memberof EmpUserNote
     */
    relationState: RelationStateInfo;
    /**
     * 
     * @type {Array<EmpGetNoteTagsResponse>}
     * @memberof EmpUserNote
     */
    noteTags: Array<EmpGetNoteTagsResponse>;
}

export function EmpUserNoteFromJSON(json: any): EmpUserNote {
    return EmpUserNoteFromJSONTyped(json, false);
}

export function EmpUserNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmpUserNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ensUserNoteTid': json['ensUserNoteTid'],
        'empNoteAuthorFirstName': json['empNoteAuthorFirstName'],
        'empNoteAuthorLastName': json['empNoteAuthorLastName'],
        'empNoteAuthorTid': json['empNoteAuthorTid'],
        'empNoteSubjectUserFirstName': json['empNoteSubjectUserFirstName'],
        'empNoteSubjectUserLastName': json['empNoteSubjectUserLastName'],
        'empNoteSubjectUserTid': json['empNoteSubjectUserTid'],
        'noteType': json['noteType'],
        'pointValue': json['pointValue'],
        'noteTypeTid': !exists(json, 'noteTypeTid') ? undefined : EnsUserNoteTypeTidFromJSON(json['noteTypeTid']),
        'createdDatetime': (new Date(json['createdDatetime'])),
        'effectiveDate': (new Date(json['effectiveDate'])),
        'empUserNoteContent': !exists(json, 'empUserNoteContent') ? undefined : json['empUserNoteContent'],
        'empUserHiddenNoteContent': !exists(json, 'empUserHiddenNoteContent') ? undefined : json['empUserHiddenNoteContent'],
        'noteAttachments': ((json['noteAttachments'] as Array<any>).map(EmpUserNoteAttachmentFromJSON)),
        'isEditable': json['isEditable'],
        'isDeletable': json['isDeletable'],
        'isRevertable': json['isRevertable'],
        'removedDateTime': !exists(json, 'removedDateTime') ? undefined : (json['removedDateTime'] === null ? null : new Date(json['removedDateTime'])),
        'relationState': RelationStateInfoFromJSON(json['relationState']),
        'noteTags': ((json['noteTags'] as Array<any>).map(EmpGetNoteTagsResponseFromJSON)),
    };
}

export function EmpUserNoteToJSON(value?: EmpUserNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ensUserNoteTid': value.ensUserNoteTid,
        'empNoteAuthorFirstName': value.empNoteAuthorFirstName,
        'empNoteAuthorLastName': value.empNoteAuthorLastName,
        'empNoteAuthorTid': value.empNoteAuthorTid,
        'empNoteSubjectUserFirstName': value.empNoteSubjectUserFirstName,
        'empNoteSubjectUserLastName': value.empNoteSubjectUserLastName,
        'empNoteSubjectUserTid': value.empNoteSubjectUserTid,
        'noteType': value.noteType,
        'pointValue': value.pointValue,
        'noteTypeTid': EnsUserNoteTypeTidToJSON(value.noteTypeTid),
        'createdDatetime': (value.createdDatetime.toISOString()),
        'effectiveDate': (value.effectiveDate.toISOString()),
        'empUserNoteContent': value.empUserNoteContent,
        'empUserHiddenNoteContent': value.empUserHiddenNoteContent,
        'noteAttachments': ((value.noteAttachments as Array<any>).map(EmpUserNoteAttachmentToJSON)),
        'isEditable': value.isEditable,
        'isDeletable': value.isDeletable,
        'isRevertable': value.isRevertable,
        'removedDateTime': value.removedDateTime === undefined ? undefined : (value.removedDateTime === null ? null : value.removedDateTime.toISOString()),
        'relationState': RelationStateInfoToJSON(value.relationState),
        'noteTags': ((value.noteTags as Array<any>).map(EmpGetNoteTagsResponseToJSON)),
    };
}

